<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import Button from '@/Components/Button/Button.vue';
import Label from '@/Components/Input/Label.vue';
const dayjs = inject('dayjs');
const props = defineProps({
    form: Object,
    positionOptions: Object,
});
const addMoreInfoToggle = ref(false);
const today = dayjs().format('YYYY-MM-DD');
const timeOptions = computed(() => {
    var quarterHours = ['00', '15', '30', '45'];
    var times = [];
    for (var i = 0; i < 24; i++) {
        for (var j = 0; j < 4; j++) {
            times.push(i + ':' + quarterHours[j]);
        }
    }
    return times;
});

const breakOptions = computed(() => {
    var times = {};

    for (var i = 0; i < 5; i++) {
        times[15 * i * 60] = 15 * i + ' min';
    }
    return times;
});

onMounted(() => {
    if (props.form.description != '') {
        addMoreInfoToggle.value = true;
    }
});
</script>

<template>
    <div class="flex gap-6 mb-2">
        <FormKit
            :label="$t('Position')"
            type="select"
            name="position_id"
            :options="{ '': $t('Select a position'), ...positionOptions }"
            select-icon="down"
            v-model="form.position_id"
            :value="form.position_id"
            :errors="form.errors.position_id"
        >
            <template #help>
                <div class="formkit-help text-xs text-blue mt-2">
                    <p>
                        {{
                            $t(
                                'Flex@ble uses the standard positions, job descriptions and hourly wages from the collective labor agreement. It is possible to add extra information to the shift.'
                            )
                        }}
                    </p>
                    <p>
                        {{ $t('Is the right position not listed? Send us an email to add it.') }}
                    </p>
                </div>
            </template>
        </FormKit>
        <div class="">
            <Label label="&nbsp;" />
            <Button
                orange
                type="button"
                class="h-10"
                :text="addMoreInfoToggle ? $t('Hide more info') : $t('Add more info')"
                @click="addMoreInfoToggle = !addMoreInfoToggle"
            />
        </div>
    </div>
    <div v-if="addMoreInfoToggle" class="my-4">
        <FormKit
            :label="$t('Description')"
            type="textarea"
            name="description"
            v-model="form.description"
            :value="form.description"
            :errors="form.errors.description"
        />
    </div>
    <div class="grid gap-5">
        <div class="grid gap-5 md:grid-cols-2">
            <FormKit
                :label="$t('Start date')"
                type="date"
                name="start-date"
                v-model="form.start_date"
                :value="form.start_date"
                :min="today"
                :errors="form.errors.start_date"
            />
            <FormKit
                :label="$t('Number of people')"
                type="select"
                select-icon="down"
                name="number_of_shifts"
                v-model="form.number_of_shifts"
                :value="form.number_of_shifts"
                :errors="form.errors.number_of_shifts"
                :options="{
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10',
                }"
            />
        </div>
        <div class="grid gap-5 md:grid-cols-3">
            <FormKit
                :label="$t('Start time')"
                type="select"
                name="start_time"
                :options="timeOptions"
                select-icon="down"
                v-model="form.start_time"
                :value="form.start_time"
                :errors="form.errors.start_time"
            />
            <FormKit
                :label="$t('End time')"
                type="select"
                name="end_time"
                :options="timeOptions"
                select-icon="down"
                v-model="form.end_time"
                :value="form.end_time"
                :errors="form.errors.end_time"
            />
            <FormKit
                :label="$t('Break')"
                type="select"
                name="break"
                :options="breakOptions"
                select-icon="down"
                v-model="form.break"
                :value="form.break"
                :errors="form.errors.break"
            />
        </div>
    </div>
</template>
